const citiesSearch = () => {
    try {
        jQuery(document).ready(function($) {
            // Mapa de países a códigos (puedes expandir esta lista según los países que tengas en la base de datos)
            var countryCodeMap = {
                'Mexico': 'MX',
                'Colombia': 'CO',
                'Ecuador': 'EC',
                'Chile': 'CL',
                'Perú': 'PE',
                'Honduras': 'HN'
            };
        
            // Cuando se cambie el país, actualizar las ciudades disponibles
            $('#country').on('change', function() {
                var selectedCountry = $(this).val(); // Obtener el valor del país seleccionado
                var countryCode = countryCodeMap[selectedCountry]; // Obtener el código del país
        
                // Verificar si hay un país seleccionado
                if (countryCode) {
                    // Realizar una solicitud AJAX para obtener las ciudades del país seleccionado
                    $.ajax({
                        url: citiescont, // URL de la petición AJAX de WordPress
                        type: 'GET',
                        data: {
                            action: 'buscar_ciudades_por_pais', // Acción personalizada de WordPress
                            country_code: countryCode // Código de país seleccionado
                        },
                        success: function(response) {
                            if (response.success) {
                                var suggestions = response.data.cities;
                                // Limpiar la lista de sugerencias de ciudades
                                $('#city-suggestions').html('');
                                
                                // Agregar las ciudades a la lista de sugerencias
                                suggestions.forEach(function(city) {
                                    $('#city-suggestions').append('<li class="suggestion-item">' + city + '</li>');
                                });
                            } else {
                                $('#city-suggestions').html('<li>No se encontraron ciudades para este país.</li>');
                            }
                        }
                    });
                } else {
                    // Si no hay país seleccionado, limpiar las sugerencias
                    $('#city-suggestions').html('');
                }
            });
        
            // Seleccionar una ciudad de la lista de sugerencias
            $(document).on('click', '.suggestion-item', function() {
                var selectedCity = $(this).text();
                $('#cityCountry').val(selectedCity); // Establecer el valor de la ciudad seleccionada en el input
                $('#city-suggestions').html(''); // Limpiar las sugerencias
            });
        
            // Hacer editable el campo de entrada cuando el usuario haga clic en él
            $('#cityCountry').on('click', function() {
                if ($('#cityCountry').val() !== '') {
                    $(this).prop('readonly', false); // Hacer editable el campo
                    $('#city-suggestions').html(''); // Limpiar las sugerencias cuando se vuelve editable
                }
            });
        });
        
    } catch (error) {

    }
}
export { citiesSearch };

const contryIva = () => {
    try {
        jQuery(document).ready(function ($) {
            var countryCodeMap = {
                'Mexico': 'MX',
                "Colombia": "CO",
                "Ecuador": "EC",
                "Chile": "CL",
                "Peru": "PE",
                "Honduras": "HN"
            };
        
            // Selecciona todos los elementos con la clase billing-form-wrapper
            var elements = document.querySelectorAll('.billing-form-wrapper');
        
            // Recorre cada elemento
            elements.forEach(function (element) {
                // Obtiene el valor del atributo data-key
                var keyValue = element.getAttribute('data-key');
        
                // Aquí puedes agregar la lógica específica para cada formulario
                var billingCountry = $(element).find("#billing-country").data("country");
        
                if (billingCountry && countryCodeMap[billingCountry]) {
                    $(element).find("#country").val(billingCountry);
                    var countryCode = countryCodeMap[billingCountry];
                    $.ajax({
                        url: ivataxesajax,
                        method: "POST",
                        data: {
                            action: "get_tax_rate",
                            country_code: countryCode
                        },
                        success: function (response) {
                            var _a;
                            if (response.success && response.data && response.data.tax_rate) {
                                var taxRate = response.data.tax_rate;
                                var rate = parseFloat(taxRate.tax_rate).toFixed(0);
                                var name = taxRate.tax_rate_name;
                                $(element).find(".profile-billing__iva").html(`<small>+${rate}% ${name}</small>`);
                            } else {
                                var errorMessage = ((_a = response.data) == null ? void 0 : _a.message) || "No se encontró información de impuestos";
                                $(element).find(".profile-billing__iva").html(`<small>${errorMessage}</small>`);
                            }
                        },
                        error: function (xhr, status, error) {
                            $(element).find(".profile-billing__iva").html(`<small>Error inesperado: ${error}</small>`);
                            console.error("Error en la solicitud AJAX:", error);
                        }
                    });
                }
        
                // Manejo del cambio en el campo #country
                $(element).find("#country").on("change", function () {
                    var selectedCountry = $(this).val();
                    var countryCode2 = countryCodeMap[selectedCountry];
        
                    if (!countryCode2) {
                        $(element).find(".profile-billing__iva").html("<small>Error: Código de país no encontrado</small>");
                        return;
                    }
        
                    $.ajax({
                        url: ivataxesajax,
                        method: "POST",
                        data: {
                            action: "get_tax_rate",
                            country_code: countryCode2
                        },
                        success: function (response) {
                            var _a;
                            if (response.success && response.data && response.data.tax_rate) {
                                var taxRate = response.data.tax_rate;
                                var rate = parseFloat(taxRate.tax_rate).toFixed(0);
                                var name = taxRate.tax_rate_name;
                                $(element).find(".profile-billing__iva").html(`<small>+${rate}% ${name}</small>`);
                            } else {
                                var errorMessage = ((_a = response.data) == null ? void 0 : _a.message) || "No se encontró información de impuestos";
                                $(element).find(".profile-billing__iva").html(`<small>${errorMessage}</small>`);
                            }
                        },
                        error: function (xhr, status, error) {
                            $(element).find(".profile-billing__iva").html(`<small>Error inesperado: ${error}</small>`);
                            console.error("Error en la solicitud AJAX:", error);
                        }
                    });
                });
            });
        });
    } catch (error) {

    }
}

  export { contryIva };

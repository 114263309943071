const Toggle = (()=>{

    const ToggleConfig = () => {
        const toggles = document.querySelectorAll('.toggle');
    
        toggles.forEach((toggle, index) => {
            // Activa el primer toggle al cargar la página
            index == 0 && toggle.classList.add('active');
    
            toggle.addEventListener('click', () => {
                const isActive = toggle.classList.contains('active'); // Verifica si está activo
    
                // Remueve "active" de todos los toggles
                toggles.forEach((toggleNested) => {
                    toggleNested.classList.remove('active');
                });
    
                // Si no estaba activo antes del clic, agrégale la clase "active"
                if (!isActive) {
                    toggle.classList.add('active');
                }
            });
        });
    };

    return {
        handleToggle : function(){
            try { ToggleConfig(); } catch (error) { console.log(error) }
        }
    }

})();


const handleToggle = ()=>{
    Toggle.handleToggle();
}

export { handleToggle }
const logingx = () => {
    try {
        jQuery(document).ready(function($) {
            $(".checkmails").click(function(event) {
              event.preventDefault();
              const email = $("#email").val().trim();
              let modaluerrorbilling = document.querySelector("#modal-social-error");
              modaluerrorbilling.style.display = "none";
              if (!email) {
                console.log("Por favor, introduce un correo electr\xF3nico.");
                const errorMsg = document.querySelector(".msg-error");
                errorMsg.textContent = "Ingresa un correo electr\xF3nico v\xE1lido";
                errorMsg.style.display = "block";
                return;
              }
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(email)) {
                console.log("Por favor, introduce un correo electr\xF3nico v\xE1lido.");
                return;
              }
              $.ajax({
                url: validateEmail.ajaxurl,
                method: "POST",
                data: {
                  action: "validate_email",
                  email
                },
                success: function(response) {
                  var data = JSON.parse(response);
                  console.log(data.message);
                  if (data.status == 1) {
                    const modalTerms = document.querySelector("#modal-terms");
                    console.log("El correo electr\xF3nico ya est\xE1 registrado.");
                    modalTerms.classList.add("active");
                  } else if (data.status == 0) {
                    console.log("El correo electr\xF3nico est\xE1 disponible.");
                    var siteurl = objecto_name.siteurl;
                    const redirecto = siteurl + "/register/?email=" + encodeURIComponent(email);
                    window.location.href = redirecto;
                  } else if (data.status == 2) {
                    document.querySelectorAll(".social-modal-error").forEach((modal) => {
                      modal.classList.remove("active");
                    });
                    let modaluerrorbilling = document.querySelector("#modal-social-error");
                    const MessageError = modaluerrorbilling.querySelector("#social-error");
                    MessageError.textContent = data.message;
                    modaluerrorbilling.classList.add("active");
                    modaluerrorbilling.style.display = "flex"; 
                    return;
                  } else {
                    console.log("Respuesta inesperada del servidor.");
                  }
                },
                error: function() {
                  console.log("Ocurri\xF3 un error en la validaci\xF3n. Intenta nuevamente.");
                }
              });
            });
          });
    } catch (error) {
        console.error("Error en logingx:", error);
    }
};

export { logingx };

const logeoGX = ()=>{
  try {
    jQuery(document).ready(function($) {
        $('#login-form-password').on('submit', function(e) {
        
            e.preventDefault(); // Prevenir el envío tradicional del formulario
  
            var loginData = {
                'action': 'custom_login_action', // El nombre de la acción
                'log': $('#user_login').val(), // Usuario
                'pwd': $('#user_pass').val(), // Contraseña
                'login_custom_nonce': $('#login_custom_nonce').val(), // Nonce
            };

            const formlogin = $('#login-form-password').data('tokenlog');
            console.log("Token obtenido: ", formlogin);

            if (formlogin == '1asd123as1asdq') {
                console.log("Datos de login: ", loginData);
                console.log("URL de AJAX: ", ajax_objectlog.ajax_url);

                // Enviar la solicitud AJAX
                $.post(ajax_objectlog.ajax_url, loginData, function(response) {
                    console.log("Respuesta recibida:", response); // Verificar la respuesta del servidor
                    if (response.success) {
                        window.location.href = response.data.redirect; // Redirigir si el login es exitoso
                    } else {
                        // Mostrar error en el formulario
                        var errorbox = $('#errordiv');
                        errorbox.html('<p>' + response.data.message + '</p>');
                        errorbox.removeClass("hiddebox");
                    }
                }, 'json');
            } else {
                console.log("Token inválido. No se envió la solicitud AJAX.");
            }
          
        });
    }); 
  } catch (error) {

  }
  
}
export{logeoGX}
//*-******************************************************************************
/**------------------------Recuperar Contraseña-------------------------------- */
const recovery = () => {
    try {
        jQuery(document).ready(function ($) {
            $('#recover-password').on('submit', function (e) {
                e.preventDefault();

                var email = $('#email').val();

                if (!email) {
                    alert('Por favor, ingresa tu correo electrónico.');
                    return;
                }

                var formData = new FormData();
                formData.append('email', email);
                formData.append('action', 'send_recovery_email');
                formData.append('nonce', myAjax.nonce); // Agregado el nonce

                fetch(myAjax.ajaxUrl, {
                    method: 'POST',
                    body: formData,
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            const modalactive = document.getElementById("modal-confirm")
                            modalactive.classList.add("active")
                            /*alert(data.message || 'Correo enviado con éxito.');*/
                        } else {
                            alert(data.message || 'Hubo un problema al enviar el correo. Intenta nuevamente.');
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        alert('Ocurrió un error inesperado.');
                    });
            });
        });
    } catch (error) {
        console.error('Error en la función de recuperación:', error);
    }
};

export { recovery };
//*-********************************************************************************
const interacciones = () => {
  try {
      jQuery(document).ready(function($) {
          const userPassInput = document.getElementById("user_pass");
          if (userPassInput) {  // Verifica si el elemento existe
              userPassInput.addEventListener("input", function() {
                  var errorbox = document.getElementById('errordiv');
                  errorbox.innerHTML = '';
                  errorbox.classList.add("hiddebox");  
              });
          } else {
              console.warn('El elemento con ID "user_pass" no existe en el DOM');
          }
          
          const userPassregister = document.getElementById("password");
            if (userPassregister) {  // Verifica si el elemento existe
                userPassregister.addEventListener("input", function() {
                    var errorbox = document.getElementById('passwderror');
                    errorbox.innerHTML = '';
                    errorbox.style.display="none"
                });
            } else {
                console.warn('El elemento con ID pasword no existe en el DOM');
            }

            const userzonemobilebtn = document.getElementById("btnzonausuario")
            if (userzonemobilebtn) {
                userzonemobilebtn.addEventListener("click", function() {
                    const acordeonusermobile = document.getElementById("usertabnav")
                    if(acordeonusermobile.classList.contains("active")){
                        if (acordeonusermobile) {
                            setTimeout(function() {
                                acordeonusermobile.classList.remove("active") 
                              }, 600);
                        }
                    }
                })
            }



            document.addEventListener( 'wpcf7mailsent', function( event ) {
                setTimeout( () => {
                    const modalin = document.getElementById("modal-newsletter")
                    modalin.classList.add("active")
                }, 3000 ); // Wait for 3 seconds to redirect.
            }, false );

            const modalcontinue = document.getElementById("btncontinue")
            modalcontinue.addEventListener("click", function(event){
                const modalin = document.getElementById("modal-newsletter")
                modalin.classList.remove("active")
            })


        });

  } catch (error) {
      console.error('Error en interacciones:', error);
  }
}

export { interacciones };


/**/
//*-********************************************************************************
const interaccionesregister = ()=>{
  try {
    jQuery(document).ready(function($) {

      var name = document.getElementById("name");
      var name_menor = document.getElementById("name-menor");
      if (name && name_menor) {
          name.addEventListener("input", function() {
              name_menor.value = name.value;
          });
      }
  
      var last_name = document.getElementById("last_name");
      var last_name_menor = document.getElementById("last_name-menor");
      if (last_name && last_name_menor) {
          last_name.addEventListener("input", function() {
              last_name_menor.value = last_name.value;
          });
      }
  
      var day = document.getElementById("day");
      var day_menor = document.getElementById("day-menor");
      if (day && day_menor) {
          day.addEventListener("input", function() {
              day_menor.value = day.value;
          });
      }
  
      var month = document.getElementById("month");
      var month_menor = document.getElementById("month-menor");
      if (month && month_menor) {
          month.addEventListener("input", function() {
              month_menor.value = month.value;
          });
      }
  
      var year = document.getElementById("year");
      var year_menor = document.getElementById("year-menor");
      if (year && year_menor) {
          year.addEventListener("input", function() {
              year_menor.value = year.value;
          });
      }
  
      var country = document.getElementById("country");
      var country_menor = document.getElementById("country-menor");
      if (country && country_menor) {
          country.addEventListener("input", function() {
              country_menor.value = country.value;
          });
      }
  
  }); 
} catch (error) {

}

}
export{interaccionesregister}

/**/

const registerGX = ()=>{
    try {
        jQuery(document).ready(function($) {
            $("#register-first-step").prop("disabled", true);
            // Función para validar la contraseña
            function validatePassword() {
              const password = $("#password").val().trim();
              const confirmPassword = $("#confirm_password").val().trim();
              const diverror = $("#passwderror");
              diverror.empty(); // Limpiar mensajes de error anteriores
          
              if (!password || !confirmPassword) {
                diverror.text("Por favor, ahora confírma la contraseña.").show();
                return false;
              }
          
              const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&.]).{6,30}$/;
              if (!passwordRegex.test(password)) {
                diverror.text("La contraseña debe tener entre 6 y 30 caracteres, incluyendo letras, números y un carácter especial.").show();
                return false;
              }
          
              if (password !== confirmPassword) {
                diverror.text("Las contraseñas no coinciden.").show();
                return false;
              }
          
              return true;
            }
          
            // Validar cuando se sale del campo de contraseña
            $("#password").on("blur", function() {
              if (validatePassword()) {
                $("#register-first-step").prop("disabled", false); // Habilitar el botón
            } else {
                $("#register-first-step").prop("disabled", true); // Deshabilitar el botón
            }
            });
          
            // Validar cuando se sale del campo de confirmación de contraseña
            $("#confirm_password").on("blur", function() {
              if (validatePassword()) {
                $("#register-first-step").prop("disabled", false); // Habilitar el botón
            } else {
                $("#register-first-step").prop("disabled", true); // Deshabilitar el botón
            }
            });
          
            // Validar cuando se envía el formulario
            $("#register-form").on("submit", function(e) {
              e.preventDefault();
          
              if (!validatePassword()) {
                return;
              }
          
              var formData = $(this).serialize();
              if (typeof ajax_object === "undefined") {
                console.error("ajax_object no está definido. Verifica wp_localize_script.");
                return;
              }
          
              setTimeout(() => {
                const body = document.body;
                if (e.target.dataset.token == "1asd123as1asdqeq" && !body.classList.contains("es-menor")) {
                  $.ajax({
                    url: ajax_object.ajaxurl,
                    type: "POST",
                    data: formData + "&action=custom_register_user&register_custom_nonce=" + ajax_object.nonce,
                    success: function(response) {
                      if (response.success) {
                        console.log(response.data);
                        var siteurl = objecto_name.siteurl;
                        window.location.href = siteurl + "/login";
                      } else {
                        console.log("Error: " + response.data);
                      }
                    },
                    error: function(xhr, status, error) {
                      console.error("Error en la solicitud AJAX:", error);
                      console.log("Hubo un error al procesar la solicitud.");
                    }
                  });
                }
              }, 500);
            });
          });
    } catch (error) {
      
    }
  }
  export{registerGX}  
/**/
const skiptbutton = ()=>{
    try {
        jQuery(document).ready(function($) {
          $('#skip-step').on('click', function(e) {
              e.preventDefault();
      
              var formData = {
                  action: 'register_tutor',
                  email_menor: $('#email-menor').val(),
                  name_menor: $('#name-menor').val(),
                  last_name_menor: $('#last_name-menor').val(),
                  day_menor: $('#day-menor').val(),
                  month_menor: $('#month-menor').val(),
                  year_menor: $('#year-menor').val(),
                  country_menor: $('#country-menor').val(),
                  passwd_menor: $('#password').val(),
              };
    
              $.post(gxData.ajaxurl, formData, function(response) {
                if (response.success) {
                    console.log('Registro sin tutor exitoso');
                    let modalconfirm = document.querySelector("#modal-register-success")
                    modalconfirm.classList.add("active");
                } else {
                    console.log('Error en el registro');
                }
            });
    
          });
        });
      
      } catch (error) {
        
      }    
}
export{skiptbutton}
/** */

const registertutorGX = () => {
    try {
        jQuery(document).ready(function($) {
            $('#register-form-tutor').on('submit', function(e) {
                e.preventDefault();

                var emailMenor = $('#email-menor').val();
                var tutorEmail = $('#tutor_email').val();

                // Verificar que los correos no sean iguales
                if (emailMenor === tutorEmail) {
                    document.querySelectorAll(".register-modal-error").forEach(modal => {
                        modal.classList.remove("active");
                    });

                    let modaluerrorbilling = document.querySelector("#modal-billing-error");
                    const MessageError = modaluerrorbilling.querySelector("#mensaje-error-register");
                    MessageError.textContent = "El correo del tutor no puede ser igual al correo del menor.";
                    modaluerrorbilling.classList.add("active");
                    return;  // Detener la ejecución si los correos son iguales
                }

                var formData = {
                    action: 'register_tutor',
                    email_menor: emailMenor,
                    name_menor: $('#name-menor').val(),
                    last_name_menor: $('#last_name-menor').val(),
                    day_menor: $('#day-menor').val(),
                    month_menor: $('#month-menor').val(),
                    year_menor: $('#year-menor').val(),
                    country_menor: $('#country-menor').val(),
                    passwd_menor: $('#password').val(),
                    tutor_name: $('#tutor_name').val(),
                    tutor_last_name: $('#tutor_last_name').val(),
                    tutor_email: tutorEmail,
                    tutor_document_number: $('#tutor_document_number').val(),
                    tutor_day: $('#tutor_day').val(),
                    tutor_month: $('#tutor_month').val(),
                    tutor_year: $('#tutor_year').val(),
                    tutor_country: $('#tutor_country').val()
                };

                // Enviar los datos si todo es correcto
                $.post(gxData.ajaxurl, formData, function(response) {
                    if (response.success) {
                        console.log('Registro exitoso');
                    } else {
                        console.log('Error en el registro');
                    }
                });

            });
        });

    } catch (error) {
        console.error('Error al registrar tutor:', error);
    }
}

export { registertutorGX };

/** Actualizar datos en el landing de usuario */

const actualizadatosformuno = ()=>{
  try {
    jQuery(document).ready(function($) {
      $('#update-user').on('click', function(e) {
          e.preventDefault();
          
          var formData = {
              action: 'update_user_profile',
              name: $('#name').val(),
              last_name: $('#last_name').val(),
              email: $('#email').val(),
              day: $('#day').val(),
              month: $('#month').val(),
              year: $('#year').val(),
              country: $('#country').val(),
              security: gxData.nonce // Asegúrate de agregar un nonce en el localizador de datos
          };
    
          $.post(gxData.ajaxurl, formData, function(response) {
            if (response.success) {
                let modalupedit = document.querySelector("#modal-edit-account");
                const tituloMessage = modalupedit.querySelector("#titulo-message");
                tituloMessage.textContent =  "Datos Personales Actualizado.";
                modalupedit.classList.add("active");
              } else {
                const modalError = document.querySelector("#modal-error");
                const errorMessage = modalError.querySelector("#error-message");
                // Actualizar el mensaje del popup
                errorMessage.textContent =  response.data || "Ocurrió un error inesperado. Por favor, inténtelo de nuevo.";
                // Mostrar el popup
                modalError.classList.add("active");
              }
          });
      });
    });
  
  } catch (error) {
    
  }
}
export{actualizadatosformuno}

const registerLinksGX = () => {
  jQuery(document).ready(function($) {
    $('#update-links').on('click', function(e) {
        e.preventDefault();

        var formData = {
            action: 'update_social_links',
            youtube: $('#youtube').val(),
            discord: $('#discord').val(),
            twitch: $('#twitch').val(),
            steam: $('#steam').val(),
        };
        console.log(JSON.stringify(formData, null, 2));
        // Realiza la solicitud AJAX a admin-ajax.php
        $.ajax({
            url: updateLiksAjax.ajaxurl, // Usando la variable global ajaxurl proporcionada por WordPress
            type: 'POST',
            data: formData,
            success: function(response) {
                if (response.success) {
                    let modalupedit = document.querySelector("#modal-edit-account");
                    const tituloMessage = modalupedit.querySelector("#titulo-message");
                    tituloMessage.textContent =  "Links actualizados.";
                    modalupedit.classList.add("active");
                  } else {
                    const modalError = document.querySelector("#modal-error");
                    const errorMessage = modalError.querySelector("#error-message");
                    // Actualizar el mensaje del popup
                    errorMessage.textContent =  response.data || "Ocurrió un error inesperado. Por favor, inténtelo de nuevo.";
                    // Mostrar el popup
                    modalError.classList.add("active");
                  }
            },
            error: function(xhr, status, error) {
                console.log(error);
                alert('Hubo un error en la solicitud.');
            }
        });
    });
  });
}
export { registerLinksGX };

const actualizartutor = () => {
    jQuery(document).ready(function($) {
        $('#update-tutor').on('click', function(e) {
            e.preventDefault();
            
            const tutorEmail = $("#tutor_email").val();
            const userEmail = $("#email").val(); 
  
            // Verifica si los correos son iguales
            if (tutorEmail === userEmail) {
              document.querySelectorAll(".register-modal-error").forEach(modal => {
                  modal.classList.remove("active");
              });
              let modaluerrorbilling = document.querySelector("#modal-billing-error");
              const MessageError = modaluerrorbilling.querySelector("#mensaje-error-register");
              MessageError.textContent =  "El correo del tutor no puede ser igual al correo del usuario principal.";
              modaluerrorbilling.classList.add("active");
              return; 
            }
  
            // Validación de los select (día, mes, año)
            let tutorDay = $("#tutor_day").val();
            let tutorMonth = $("#tutor_month").val();
            let tutorYear = $("#tutor_year").val();
  
            if (!tutorDay) {
                document.querySelectorAll(".register-modal-error").forEach(modal => {
                    modal.classList.remove("active");
                });
  
                let modaluerrorbilling = document.querySelector("#modal-billing-error");
                let MessageError = modaluerrorbilling.querySelector("#mensaje-error-register");
                MessageError.textContent = "Por favor, selecciona la fecha de nacimiento del tutor.";
                modaluerrorbilling.classList.add("active");
                return;  // Detener la ejecución si hay un campo vacío
            }
  
            const tutorData = {
                action: 'update_tutor_data',
                nonce: updateTutorAjax.nonce,  // Asegúrate de que 'nonce' esté en los datos
                tutor_name: $('#tutor_name').val(),
                tutor_last_name: $('#tutor_last_name').val(),
                tutor_email: $('#tutor_email').val(),
                tutor_day: tutorDay,
                tutor_month: tutorMonth,
                tutor_year: tutorYear,
                tutor_country: $('#tutor_country').val(),
            };
  
            console.log('tutordata: ', tutorData);
  
            $.ajax({
                url: updateTutorAjax.ajax_url,
                type: 'POST',
                data: tutorData,
            })
            .done(function(response) {
              if (response.success) {
                  let modalupedit = document.querySelector("#modal-edit-account");
                  const tituloMessage = modalupedit.querySelector("#titulo-message");
                  tituloMessage.textContent = "Actualización de Tutor exitosa.";
                  modalupedit.classList.add("active");
                } else {
                  const modalError = document.querySelector("#modal-error");
                  const errorMessage = modalError.querySelector("#error-message");
                  // Actualizar el mensaje del popup
                  errorMessage.textContent = response.data || "Ocurrió un error inesperado. Por favor, inténtelo de nuevo.";
                  // Mostrar el popup
                  modalError.classList.add("active");
                }
            })
            .fail(function(jqXHR, textStatus, errorThrown) {
                alert('Hubo un error en la solicitud AJAX: ' + textStatus + ' - ' + errorThrown);
            });
        });
    });
  };  
export { actualizartutor };

const actualizarContrasena = () => {
  jQuery(document).ready(function($) {
      $('#update-passwd').on('click', function(e) {
          e.preventDefault();

          const newPassword = $('#new_password').val();
          const confirmPassword = $('#confirm_password').val();

          // Validar que las contraseñas coincidan
          if (newPassword !== confirmPassword) {
            const modalError = document.querySelector("#modal-error");
            const errorMessage = modalError.querySelector("#error-message");
            // Actualizar el mensaje del popup
            errorMessage.textContent =  "Las contrase\xF1as no coinciden.";
            // Mostrar el popup
            modalError.classList.add("active");
            return;
          }

          // Datos que se enviarán en la solicitud AJAX
          const data = {
              action: 'update_password',
              new_password: newPassword,
              nonce: ajaxPassword.nonce // Incluye el nonce en la solicitud
          };

          $.ajax({
              url: ajaxPassword.ajax_url,
              type: 'POST',
              data: data,
              success: function(response) {
                if (response.success) {
                    let modalupedit = document.querySelector("#modal-edit-account");
                    const tituloMessage = modalupedit.querySelector("#titulo-message");
                    tituloMessage.textContent =  "Cotraseña Actualizada.";
                    modalupedit.classList.add("active");
                  } else {
                    const modalError = document.querySelector("#modal-error");
                    const errorMessage = modalError.querySelector("#error-message");
                    // Actualizar el mensaje del popup
                    errorMessage.textContent =  response.data || "Ocurrió un error inesperado. Por favor, inténtelo de nuevo.";
                    // Mostrar el popup
                    modalError.classList.add("active");
                  }
                }
          });
      });
  });
};
export { actualizarContrasena };

const actualizaravatar = () => {
    // Asegúrate de que la función se ejecuta solo en páginas donde el botón debería existir

    const avatarButton = document.getElementById('update-avatar-btn');

    if (avatarButton) {
        avatarButton.addEventListener('click', function(e) {
            e.preventDefault();

            const formData = new FormData(document.getElementById('update-avatar-form'));
            formData.append('action', 'update_user_avatar');
            formData.append('nonce', updateAvatar.nonce);

            fetch(updateAvatar.ajax_url, {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    document.querySelectorAll(".avatar-modal").forEach(modal => {
                        modal.classList.remove("active");
                      });
                      let modalupavatar = document.querySelector("#modal-update-avatar");
                      modalupavatar.classList.add("active");
                } else {
                    alert('Error al  actualizar la imagen: ' + data.data);
                }
            })
            .catch(error => console.error('Error:', error));
        });
    } else {
        console.info('El botón con ID "update-avatar-btn" no está presente en esta página.');
    }
};
export { actualizaravatar };

const interaccionesmodal = () => {
    try {
        jQuery(document).ready(function($) {
            // Configuración de los botones de la modal
            $(".editbtn").on("click", function() {
                $(".avatar-modal").addClass("active");
            });

            $(".closeavmodal").on("click", function() {
                $(".avatar-modal").removeClass("active");
            });

            // Verificar la existencia del formulario
            const form = document.getElementById('custom-billing-form');
            if (!form) {
                console.info('Formulario "custom-billing-form" no encontrado en esta página.');
                return;
            }

            // Agregar evento de envío al formulario
            form.addEventListener('submit', function(e) {
                e.preventDefault();

                const formData = {
                    action: 'actualizar_billing', // Acción del AJAX
                    name: $('#name').val(),
                    last_name: $('#last_name').val(),
                    address: $('#address').val(),
                    country: $('#country').val(),
                    city: $('#cityCountry').val(),
                    security: customBillingForm.nonce // Aquí se agrega el nonce
                };

                // Realizar la solicitud AJAX usando $.post
                $.post(customBillingForm.ajaxurl, formData, function(response) {
                    if (response.success) {
                        alert(response.message || 'Datos actualizados correctamente.');
                    } else {
                        alert(response.message || 'Error al actualizar los datos.');
                    }
                }).fail(function() {
                    alert('Hubo un problema al enviar los datos. Intenta nuevamente.');
                });
            });
        });
    } catch (error) {
        console.error('Error en interaccionesmodal:', error);
    }
};

export { interaccionesmodal };


const actualizarBilling = () => {
    try {
        const form = document.getElementById('custom-billing-form');

        // Validar que el formulario existe
        if (!form) {
            console.error('Formulario "custom-billing-form" no encontrado.');
            return;
        }

        form.addEventListener('submit', function(e) {
            e.preventDefault();

            const formData = {
                action: 'actualizar_billing', // Acción del AJAX
                name: $('#name').val(),
                last_name: $('#last_name').val(),
                address: $('#address').val(),
                country: $('#country').val(),
                city: $('#cityCountry').val(),
                security: customBillingForm.nonce // Aquí se agrega el nonce
            };

            // Realizar la solicitud AJAX usando $.post
            $.post(customBillingForm.ajaxurl, formData, function(response) {
                if (response.success) {
                    alert(response.message || 'Datos actualizados correctamente.');
                    location.reload();
                } else {
                    alert(response.message || 'Error al actualizar los datos.');
                }
            }).fail(function() {
                alert('Hubo un problema al enviar los datos. Intenta nuevamente.');
            });
        });
    } catch (error) {
        console.error('Error en actualizarBilling:', error);
    }
};

export { actualizarBilling };

const AgregarBilling = () => {
    try {
document.addEventListener('DOMContentLoaded', function() {
    let formCounter = 1;  // Inicia el contador de formularios
    
    // Función para agregar un nuevo formulario
    document.getElementById('add-new-billing').addEventListener('click', function() {
        // Clonar el primer formulario
        console.log("agregar billing")
        var newForm = document.querySelector('.billing-form').cloneNode(true);

        // Actualizar el id y nombre para hacerlos únicos
        var inputs = newForm.querySelectorAll('input, select');
        inputs.forEach(function(input, index) {
            input.id = input.id.replace(/\d+$/, formCounter);
            input.name = input.name.replace(/\[\d+\]$/, `[${formCounter}]`);
        });

        // Actualizar el botón de eliminar para este nuevo formulario
        var removeButton = newForm.querySelector('.remove-billing-form');
        removeButton.setAttribute('data-form-id', formCounter);
        
        // Añadir el nuevo formulario al contenedor
        document.getElementById('billing-forms-container').appendChild(newForm);

        // Incrementar el contador para el próximo formulario
        formCounter++;
    });

    // Función para eliminar un formulario
    document.getElementById('billing-forms-container').addEventListener('click', function(event) {
        if (event.target.classList.contains('remove-billing-form')) {
            const formId = event.target.getAttribute('data-form-id');
            document.getElementById('billing-form-' + formId).remove();
        }
    });
});
} catch (error) {
    console.error('Error en agregar nuevo formulario:', error);
}
};

export { AgregarBilling };

const fondoswallet = () => {
    try {
        jQuery(document).ready(function ($) {
            $('#add_funds_button').on('click', function (e) {
                e.preventDefault();
        
                const productId = $('#product_id').val();
                const errorMsg = $('.msg-error');
                const tycIdError = $("#tycError");
                const tycId = $('#termsCheckbox').is(':checked');
                if (!tycId) { 
                    tycIdError.text("Debes aceptar los términos y condiciones").show();
                    return;
                  }
                if (!productId) {
                    errorMsg.text('Selecciona una opción').show();
                    return;
                }
        
                errorMsg.hide();
        
                // Llamada AJAX para agregar al carrito
                $.ajax({
                    url: wc_add_to_cart_params.ajax_url,
                    type: 'POST',
                    data: {
                        action: 'add_to_cart',
                        product_id: productId,
                    },
                    success: function (response) {
                        if (response.success) {
                            //alert('Producto añadido al carrito');
                            //location.reload();
                            window.location.href = '/carrito/';
                        } else {
                            alert('Error al añadir el producto');
                        }
                    },
                    error: function () {
                        alert('Error en la solicitud.');
                    },
                });
            });
        });
    } catch (error) {
        console.error('Error en fondoswallet:', error);
    }
};

export { fondoswallet };

const eliminardecarrito = () => {
    try {
        jQuery(document).ready(function ($) {
            $('body').on('click', '.remove-from-cart', function (e) {
                console.log("remover de carrito")
                e.preventDefault();
        
                const removeLink = $(this).attr('href');
        
                $.ajax({
                    url: removeLink,
                    type: 'GET',
                    success: function () {
                        // Recargar el contenido del carrito
                        location.reload();
                    },
                    error: function () {
                        alert('Error al eliminar el producto del carrito.');
                    },
                });
            });
        });
    } catch (error) {
        console.error('Error en eliminardecarrito:', error);
    }
};

export { eliminardecarrito };


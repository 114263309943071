
import "../sass/main.scss";
//Components
import { 
    HeaderTypeCurrency, 
    HeaderHamburger, 
    DarkLightMode, 
    handleSwiper, 
    handleTab ,
    handlePopup,
    handleLoader,
    handleFilterRange,
    handleFilter,
    handleToggle,
    handleSelect,
    handleShowMore,
    handleEnableForm,
    searchG,
    logingx,
    logeoGX,
    interacciones,
    interaccionesregister,
    registerGX,
    skiptbutton,
    registertutorGX,
    actualizadatosformuno,
    registerLinksGX,
    actualizartutor,
    actualizarContrasena,
    actualizaravatar,
    interaccionesmodal,
    AgregarBilling,
    fondoswallet,
    eliminardecarrito,
    socialFb,
    recovery,
    contryIva,
    // arrowhome,
    //noticesajax
    
} from './components/index';
//Validation for forms
import { 
    ValidateFormHandleEvent,
 } from './formValidation/Index';
//Forms
import { 
    handleLoginForm,
    handleRegisterForm
} from './form/index';

window.addEventListener('load', ()=>{
    HeaderTypeCurrency();
    HeaderHamburger();
    DarkLightMode();
    handleSwiper();
    handleTab();
    handlePopup();
    ValidateFormHandleEvent();
    handleFilterRange();
    handleFilter();
    handleToggle();
    handleSelect();
    handleShowMore();
    handleEnableForm();
    //Forms
    socialFb();
    searchG();
    handleLoginForm();
    handleRegisterForm();
    logingx();
    logeoGX();
    interacciones();
    interaccionesregister();
    registerGX();
    skiptbutton();
    registertutorGX();
    actualizadatosformuno();
    registerLinksGX();
    actualizartutor();
    actualizarContrasena();
    actualizaravatar();
    interaccionesmodal();
    AgregarBilling();
    fondoswallet();
    eliminardecarrito();
    recovery();
    contryIva();
    // arrowhome();
    
});

window.addEventListener('DOMContentLoaded', ()=>{
    
    var templateUrl = object_name.templateUrl;

    
    var thecountrycurrency = document.getElementById('countrycurrency');
    var links = thecountrycurrency.querySelectorAll('a[rel]');
    links.forEach(function(link) {
        var currency = link.getAttribute('rel'); // Obtiene el valor del atributo 'rel'

        if (currency === 'COP') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagcolombia.svg" alt="Bandera GamerXperience"> COL';
        } else if (currency === 'USD') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagecuador.svg" alt="Bandera GamerXperience"> ECU';
        } else if (currency === 'PEN') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagperu.svg" alt="Bandera GamerXperience"> PER';
        } else if (currency === 'MXN') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagmexico.svg" alt="Bandera GamerXperience"> MEX';
        } else if (currency === 'CLP') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flagchile.svg" alt="Bandera GamerXperience"> CHL';
        } else if (currency === 'HNL') {
            link.innerHTML = '<img src="'+templateUrl+'/images/header/flaghonduras.svg" alt="Bandera GamerXperience"> HND';
        }
    })

    handleLoader();
});

document.addEventListener('DOMContentLoaded', function () {
    // Obtener la fecha actual
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Mes actual (1-12)
    const currentDay = currentDate.getDate();
  
    const tutorYear = document.getElementById('tutor_year');
    const tutorMonth = document.getElementById('tutor_month');
    const tutorDay = document.getElementById('tutor_day');
  
    // Llenar los años hasta el 2007 como máximo
    for (let year = currentYear - 18; year >= 1900; year--) {
        const option = document.createElement('option');
        option.value = year;
        option.textContent = year;
        tutorYear.appendChild(option);
    }
  
    // Función para comprobar los días según el mes y si el año es bisiesto
    function getDaysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }
  
    // Actualizar los días dependiendo del mes y año
    function updateDays() {
        const selectedYear = parseInt(tutorYear.value);
        const selectedMonth = parseInt(tutorMonth.value);
  
        if (!selectedYear || !selectedMonth) return;
  
        // Obtener el número de días del mes
        const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
  
        // Limpiar los días previos
        tutorDay.innerHTML = '<option value="" selected disabled>Día</option>';
  
        // Llenar los días según el mes y año
        for (let day = 1; day <= daysInMonth; day++) {
            const option = document.createElement('option');
            option.value = day < 10 ? '0' + day : day;
            option.textContent = day < 10 ? '0' + day : day;
            tutorDay.appendChild(option);
        }
    }
  
    // Validación del día de nacimiento según los valores actuales
    function validateDate() {
        const selectedYear = parseInt(tutorYear.value);
        const selectedMonth = parseInt(tutorMonth.value);
        const selectedDay = parseInt(tutorDay.value);
  
        // Verificar si la persona tiene más de 18 años
        if (selectedYear && selectedMonth && selectedDay) {
          const birthDate = new Date(selectedYear, selectedMonth - 1, selectedDay);
          const age = currentYear - birthDate.getFullYear();
      
          if (age < 18) {
            document.querySelectorAll(".register-modal-error").forEach(modal => {
                modal.classList.remove("active");
              });
              let modaluerrorbilling = document.querySelector("#modal-billing-error");
              const MessageError = modaluerrorbilling.querySelector("#mensaje-error-register");
              MessageError.textContent =  "Debes tener al menos 18 años.";
              modaluerrorbilling.classList.add("active");
              tutorDay.value = '';
              return;
          } else if (age === 18) {
              if (selectedMonth > currentMonth || (selectedMonth === currentMonth && selectedDay > currentDay)) {
                document.querySelectorAll(".register-modal-error").forEach(modal => {
                    modal.classList.remove("active");
                  });
                  let modaluerrorbilling = document.querySelector("#modal-billing-error");
                  const MessageError = modaluerrorbilling.querySelector("#mensaje-error-register");
                  MessageError.textContent =  "Debes tener al menos 18 años.";
                  modaluerrorbilling.classList.add("active");
                  tutorDay.value = '';
                  return;
              }
          }
      }
  
        return true;
    }
  
    tutorYear.addEventListener('change', function () {
        updateDays();
        validateDate();
    });
  
    tutorMonth.addEventListener('change', function () {
        updateDays();
        validateDate();
    });
  
    tutorDay.addEventListener('change', function () {
        validateDate();
    });
  });
  
  //arraw home
  document.addEventListener("DOMContentLoaded", function () {
    const sections = Array.from(document.querySelectorAll("section")).filter((section) => section.id);
    const scrollToBottomButton = document.getElementById("scrollToBottom");
    const scrollToTopButton = document.getElementById("scrollToTop");
    let currentSectionIndex = 0;
  
    // Función para actualizar la visibilidad de los botones
    function updateButtonVisibility() {
      if (currentSectionIndex === sections.length - 1) {
        // Si estamos en la última sección, mostrar scrollToTop y ocultar scrollToBottom
        scrollToTopButton.style.display = "block";
        scrollToBottomButton.style.display = "none";
      } else {
        // En cualquier otra sección, mostrar scrollToBottom y ocultar scrollToTop
        scrollToBottomButton.style.display = "block";
        scrollToTopButton.style.display = "none";
      }
    }
  
    // Evento para el botón de desplazamiento hacia abajo
    scrollToBottomButton.addEventListener("click", function () {
      if (sections.length === 0) return;
      if (currentSectionIndex < sections.length - 1) {
        currentSectionIndex++;
        sections[currentSectionIndex].scrollIntoView({ behavior: "smooth" });
        updateButtonVisibility();
      }
    });
  
    // Evento para el botón de desplazamiento hacia arriba
    scrollToTopButton.addEventListener("click", function () {
      if (sections.length === 0) return;
      // Desplazarse al inicio de la página
      window.scrollTo({ top: 0, behavior: "smooth" });
      // Actualizar el índice de la sección actual a 0 (primera sección)
      currentSectionIndex = 0;
      // Actualizar la visibilidad de los botones
      updateButtonVisibility();
    });
  
    // Evento para detectar el scroll del usuario
    window.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentSectionIndex = index;
          updateButtonVisibility();
        }
      });
    });
  
    // Inicializar la visibilidad de los botones al cargar la página
    updateButtonVisibility();
  }); 
 
  